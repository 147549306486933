
import React from 'react';
import styled from 'styled-components';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';

const EmpStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  background-color: #ffffffab;
    -webkit-backdrop-filter: blur(52px);
    backdrop-filter: blur(52px);
  div {
    list-style: none;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default function EmpresasPage() {
  return (
    < >
        <Article title="Empresas">

                <EmpStyles >
                    <div class="style-scope news-article">Administradora Boliviana de Carreteras, La Paz, Bolivia.</div>
                    <div class="style-scope news-article">Agrícola Himalaya, Bitaco, Valle del Cauca.</div>
                    <div class="style-scope news-article">Agropecuaria Chía Corp Bolivia S.A., Santa Cruz, Bolivia.</div>
                    <div class="style-scope news-article">Aguas de Buga, Buga, Colombia.</div>
                    <div class="style-scope news-article">Aire Caribe, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Alcaldía de Armenia, Armenia, Colombia</div>
                    <div class="style-scope news-article">Alcaldía de Buga, Buga, Colombia.</div>
                    <div class="style-scope news-article">Alcaldía de Cali, Cali, Colombia.</div>
                    <div class="style-scope news-article">Alcaldía de Palmira, Palmira, Colombia.</div>
                    <div class="style-scope news-article">Alcaldía de Tuluá, Tuluá, Colombia.</div>
                    <div class="style-scope news-article">Alimentos Cárnicos, Barranquilla, Colombia.</div>
                    <div class="style-scope news-article">Alpina, Entrerríos, Antioquia.</div>
                    <div class="style-scope news-article">Aluminios India, Cali, Colombia</div>
                    <div class="style-scope news-article">Arrocera Los Corrales - ALCSA, Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">Atunec, Barranquilla, Colombia.</div>
                    <div class="style-scope news-article">Auditoría General de la Nación, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Azul K, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Baker and Hughes, Quito, Ecuador.</div>
                    <div class="style-scope news-article">BanCoomeva, Cali, Colombia.</div>
                    <div class="style-scope news-article">BASC, Pereira, Colombia.</div>
                    <div class="style-scope news-article">Bavaria, Cali, Bucaramanga, Medellín, Colombia.</div>
                    <div class="style-scope news-article">Belca S.A. Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">BICO, Carvajal, Cali, Colombia.</div>
                    <div class="style-scope news-article">Cadbury Adams, Cali, Colombia.</div>
                    <div class="style-scope news-article">Café La Palma, Popayán, Colombia.</div>
                    <div class="style-scope news-article">Canacol, extracción de gas, La Esperanza, Montería, Colombia.</div>
                    <div class="style-scope news-article">Cedrovet, Santa Cruz, Bolivia.</div><div class="style-scope news-article">Centelsa, Cali, Colombia.</div>
                    <div class="style-scope news-article">Cepcolsa, Bogotá, Colombia.</div><div class="style-scope news-article">Clínica Imbanaco, Cali, Colombia.</div>
                    <div class="style-scope news-article">Clínica Nueva Bogotá, Colombia.</div><div class="style-scope news-article">Club Campestre Cali, Colombia.</div>
                    <div class="style-scope news-article">Cámara Boliviano – Alemana, La Paz, Bolivia.</div>
                    <div class="style-scope news-article">Cámara de Comercio de Buga, Buga, Colombia.</div>
                    <div class="style-scope news-article">Cámara de Industria, Guayaquil, Ecuador</div>
                    <div class="style-scope news-article">Coats Cadena, Pereira, Colombia.</div>
                    <div class="style-scope news-article">Colmena, Cali, Colombia.</div>
                    <div class="style-scope news-article">Colegio Ana Julia Holguín de Hurtado, Palmira, Colombia.</div>
                    <div class="style-scope news-article">Colegio Hispanoamericano, Cali, Colombia.</div>
                    <div class="style-scope news-article">Colegio La Presentación, Cali, Colombia.</div>
                    <div class="style-scope news-article">Colegio San Luis, Cali, Colombia.</div>
                    <div class="style-scope news-article">Colvanes, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Comarrico, Barranquilla, Colombia.</div>
                    <div class="style-scope news-article">Comfandi, Cali, Colombia.</div>
                    <div class="style-scope news-article">Compensar, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Conal – Compañía Nacional de Alimentos, Cali, Colombia.</div>
                    <div class="style-scope news-article">Consorcio, Cali, Colombia.</div>
                    <div class="style-scope news-article">Contraloría de Bogotá, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Contraloría de Buenaventura, Colombia.</div>
                    <div class="style-scope news-article">Coomeva, Cali, Colombia.</div>
                    <div class="style-scope news-article">Cooperativa de Avianca, Coopava, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Cooprocenva, Sevilla, Valle del Cauca, Colombia.</div>
                    <div class="style-scope news-article">Coosalud, Costa Atlántica, Valledupar, Sincelejo, Montería, Colombia.</div>
                    <div class="style-scope news-article">Coronilla, Cochabamba, Bolivia.</div>
                    <div class="style-scope news-article">Corporación Autónoma de Cundinamarca (CAR), Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Corporación NASA Kiwe, Popayán, Colombia.</div>
                    <div class="style-scope news-article">Cósmica, Cuenca, Ecuador.</div>
                    <div class="style-scope news-article">Cusiana Gas, Villavicencio, Colombia.</div>
                    <div class="style-scope news-article">CVC, Corporación Autónoma del Valle del Cauca, Cali, Colombia.</div>
                    <div class="style-scope news-article">Danny Venta Directa, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">DARSA, Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">Datacentrum, Cali, Colombia.</div>
                    <div class="style-scope news-article">Departamento de Buceo y Salvamento, Cartagena, Colombia.</div>
                    <div class="style-scope news-article">DARSA - Destiladora de Alcoholes y Rones, Tulula, Guatemala.</div>
                    <div class="style-scope news-article">Distribución y Mercadeo – D&amp;M, Santa Cruz, Bolivia.</div>
                    <div class="style-scope news-article">ECI, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Ecopetrol, Neiva, Colombia.</div>
                    <div class="style-scope news-article">E- Dea, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">EFIC – Consultores, Neiva, Colombia.</div>
                    <div class="style-scope news-article">EMAS – Empresas Municipales de Aseo, Cali, Colombia.</div>
                    <div class="style-scope news-article">EMEL (Empresa Eléctrica de Chile), Santiago de Chile, Chile.</div>
                    <div class="style-scope news-article">Empaques Automáticos de El Salvador, La Libertad, El Salvador.</div>
                    <div class="style-scope news-article">Empresas Públicas de Armenia, EPA, Armenia, Colombia.</div>
                    <div class="style-scope news-article">Empresa de Energía de Pereira, EPP, Pereira, Colombia.</div>
                    <div class="style-scope news-article">Enriko, Ltda., Cali, Colombia.</div>
                    <div class="style-scope news-article">Epsa, Cali, Colombia.</div>
                    <div class="style-scope news-article">Eternit, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Exportadora Agroindustrial Liebes – Exagroli, Santa Ana, El Salvador.</div>
                    <div class="style-scope news-article">Fertilizantes Maya, Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">FISDL, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Formas y Valores, Cali, Colombia.</div>
                    <div class="style-scope news-article">Frigorífico BLE, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Gayro S.A.S., Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Gobernación de Boyacá, Tunja, Colombia.</div>
                    <div class="style-scope news-article">Gobernación de Risaralda, Pereira, Colombia.</div>
                    <div class="style-scope news-article">Gobernación de San Andrés, San Andrés, Colombia.</div>
                    <div class="style-scope news-article">Gráficas Los Andes, Cali, Colombia.</div>
                    <div class="style-scope news-article">Gralco, Barranquilla, Colombia.</div>
                    <div class="style-scope news-article">Gran Alimento, Santa Cruz, Bolivia.</div>
                    <div class="style-scope news-article">Granel S.A., Choloma, Cortés, Honduras.</div>
                    <div class="style-scope news-article">Grupo SI 99, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Harinera del Valle, Cali, Colombia.</div>
                    <div class="style-scope news-article">Hershey’s, USA, USA.</div>
                    <div class="style-scope news-article">Hospital Duitama, Duitama, Colombia.</div>
                    <div class="style-scope news-article">Hospital en Casa, Coomeva, Cali, Colombia.</div>
                    <div class="style-scope news-article">Hospital Tomás Uribe Uribe, Tuluá, Colombia.</div>
                    <div class="style-scope news-article">Hospital Meissen, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Hospital Nazareth, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Hotel Dann, Cali, Colombia.</div>
                    <div class="style-scope news-article">IC Consultoría, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Icontec, Bogotá, Cali, Colombia.</div>
                    <div class="style-scope news-article">Industria de Harinas Tuluá Ltda, Tuluá, Colombia.</div>
                    <div class="style-scope news-article">Industria de Licores del Valle, Cali, Colombia.</div>
                    <div class="style-scope news-article">Industria Licorera Euskadi, Nahualate, Guatemala.</div>
                    <div class="style-scope news-article">Industria Licorera Guatemalteca, Mixco, Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">Industria Licorera Quezalteca, Mixco / Nahualate, Guatemala.</div>
                    <div class="style-scope news-article">Industria Molinera de Caldas, Manizales, Colombia.</div>
                    <div class="style-scope news-article">Industrias Frusabor, Cali, Colombia.</div>
                    <div class="style-scope news-article">Industrias Kral, Santa Cruz, Bolivia</div>
                    <div class="style-scope news-article">Infivalle, Cali, Colombia.</div>
                    <div class="style-scope news-article">Ingenio del Cauca, Santander, Cauca, Colombia.</div>
                    <div class="style-scope news-article">Ingenio La Cabaña, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Ingenio Manuelita, Palmira, Colombia.</div>
                    <div class="style-scope news-article">Ingenio Mayagüez, Palmira, Colombia.</div>
                    <div class="style-scope news-article">Ingenio Pichichí, Pradera, Colombia.</div>
                    <div class="style-scope news-article">Ingenio Providencia, El Cerrito, Valle, Colombia.</div>
                    <div class="style-scope news-article">Ingenio Riopaila Castilla, Pradera, Valle, Colombia.</div>
                    <div class="style-scope news-article">Ingenio San Carlos, Tuluá, Colombia.</div>
                    <div class="style-scope news-article">Ingeominas, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Instituto Colombiano de Bienestar Familiar (ICBF), Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Instituto de Medicina Legal, Cali, Colombia.</div>
                    <div class="style-scope news-article">Instituto nacional de Cancerología, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Ingredion, Cali, Colombia.</div>
                    <div class="style-scope news-article">Intalpel, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Intergraphic, Cali, Colombia.</div>
                    <div class="style-scope news-article">IPSE, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Jairo Maya Salazar, Cali, Colombia</div>
                    <div class="style-scope news-article">JGB, Cali, Colombia.</div>
                    <div class="style-scope news-article">La Estrella, La Paz, Bolivia.</div>
                    <div class="style-scope news-article">La Salle, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">La Sultana, La Libertad, El Salvador.</div>
                    <div class="style-scope news-article">Licorera Zacapaneca, Mixco / Nahualate, Guatemala.</div>
                    <div class="style-scope news-article">Listos S.A, Cali y Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Lloreda, Yumbo, Valle, Colombia.</div>
                    <div class="style-scope news-article">Lloreda, Barranquilla, Colombia.</div>
                    <div class="style-scope news-article">McCormick, La Libertad, El Salvador.</div>
                    <div class="style-scope news-article">Mama-Ía, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Manitoba, Cali, Colombia.</div>
                    <div class="style-scope news-article">Manuplast, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Megaproyectos, Cali, Colombia.</div>
                    <div class="style-scope news-article">Melher, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Midland Oil Tools Colombia, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Ministerio de Educación Nacional, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Ministerio de Defensa, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">MS Consultores, Cali, Colombia.</div>
                    <div class="style-scope news-article">Monopol, La Paz, Bolivia.</div>
                    <div class="style-scope news-article">Nelson Royero, Cali, Colombia.</div>
                    <div class="style-scope news-article">Nestlé, Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">Nutritec, Cali, Colombia.</div>
                    <div class="style-scope news-article">Open System, Cali, Colombia.</div>
                    <div class="style-scope news-article">PALL, Productos Alimenticios La Locura, Cali, Colombia.</div>
                    <div class="style-scope news-article">Pastas Comarrico, Barranquilla, Colombia.</div>
                    <div class="style-scope news-article">Patronic – Sabor Amigo, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Plegacol, Cali, Colombia.</div>
                    <div class="style-scope news-article">Politécnico Empresarial Colombiano, PEC, Cali, Colombia.</div>
                    <div class="style-scope news-article">Postobón, Cali, Colombia.</div>
                    <div class="style-scope news-article">Producción Gráfica Editores, Cali, Colombia.</div>
                    <div class="style-scope news-article">Productora de Jugos, Tuluá, Valle, Colombia.</div>
                    <div class="style-scope news-article">R &amp; Londoño, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Redes &amp; Proyectos, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Regional de Seguridad del Ejército, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Rivercol, Yumbo, Valle, Colombia.</div>
                    <div class="style-scope news-article">Seguridad Berna, Cali, Valle, Colombia.</div>
                    <div class="style-scope news-article">Seguros G&amp;T, Guatemala, Guatemala.</div>
                    <div class="style-scope news-article">SENA, Villavicencio, Colombia.</div>
                    <div class="style-scope news-article">SEMEXA, Semillero Experimental, Santa Cruz, Bolivia.</div>
                    <div class="style-scope news-article">Serfunllanos, Villavicencio, Colombia.</div>
                    <div class="style-scope news-article">Sertecpet, Quito, Ecuador.</div>
                    <div class="style-scope news-article">Servicios Asociados, Neiva, Colombia.</div>
                    <div class="style-scope news-article">Servientrega, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Sun Chemical, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Tecnosur, Jamundí, Valle, Colombia.</div>
                    <div class="style-scope news-article">Teramed, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Termoemcali, Yumbo, Colombia.</div>
                    <div class="style-scope news-article">Termoencogibles, San Salvador, El Salvador.</div>
                    <div class="style-scope news-article">Unicáncer, Cali, Valle, Colombia.</div>
                    <div class="style-scope news-article">Unilever, Santiago de Chile, Chile.</div>
                    <div class="style-scope news-article">Unimetro, Cali, Colombia.</div>
                    <div class="style-scope news-article">Universidad Autónoma de Occidente, Cali, Colombia.</div>
                    <div class="style-scope news-article">Universidad Cooperativa de Colombia, Cali, Colombia.</div>
                    <div class="style-scope news-article">Universidad de América, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Universidad del Valle, Cali, Colombia.</div>
                    <div class="style-scope news-article">Universidad Javeriana, Cali, Colombia.</div>
                    <div class="style-scope news-article">Universidad Jorge Tadeo Lozano, Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Universidad Nacional, Palmira, Valle, Colombia.</div>
                    <div class="style-scope news-article">Universidad UPTC, Sogamoso, Colombia.</div>
                    <div class="style-scope news-article">Visión y Marketing, Cali y Bogotá, Colombia.</div>
                    <div class="style-scope news-article">Walmart de México y Centroamérica, San Salvador, El Salvador.</div>
                </EmpStyles>
       
        </Article>
    </>
  );
}
